<template>
  <div>
    <el-input
        v-model="iptValue"
        :maxlength="maxlength"
        :size="size"
        :style="{
          width: width
        }"
        :clearable="clearable"
        :placeholder="placeholder"
        @input="handlerInput"
    >
      <el-button slot="append" @click="handleChange">搜索</el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    value: String,
    maxlength: {
      type: [Number, String],
      default: 30
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    size: {
      type: String,
      default: 'small'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '400px'
    }
  },
  data () {
    let that = this
    return {
      iptValue: that.value || ''
    }
  },
  methods: {
    handlerInput (e) {
      this.$emit('input', e)
    },
    handleChange () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style scoped>
  /deep/ .el-input__inner{
    border-color: #ff9123 !important;
  }
  /deep/ .el-input-group__append{
    border-color: #ff9123 !important;
    color: #fff !important;
    background-color: #ff9123 !important;
  }
</style>
