<template>
  <div class="zx-sort uf uf-ac" @click="handleClick(value)">
    <div class="text">{{ text }}</div>
    <div class="uf uf-column uf-ac uf-pc">
      <div
          class="icon-box el-icon-caret-top"
          :class="{'active': value === 'asc'}"
          @click.stop="handleClick(null)"
      ></div>
      <div
          class="icon-box el-icon-caret-bottom"
          :class="{'active': value === 'desc'}"
          @click.stop="handleClick('asc')"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '综合'
    },
    value: { // [desc, asc, null]
      type: String
    }
  },
  methods: {
    handleClick (value) {
      if (!value || value === '') {
        this.$emit('input', 'asc')
      } else if (value === 'asc') {
        this.$emit('input', 'desc')
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style scoped>
  .zx-sort{
    height: 32px;
    overflow: hidden;
    line-height: 1;
    cursor: pointer;
    padding: 4px 0;
  }
  .text{
    font-size: 14px;
    color: #666;
    margin-top: 3px;
    padding-right: 6px;
  }
  .icon-box{
    font-size: 14px;
    color: #888;
  }
  .icon-box:nth-of-type(2){
    margin-top: -6px;
  }
  .active{
    color: #ff9123 !important;
  }
</style>
