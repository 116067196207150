<template>
  <div class="container">
    <el-form ref="form" :model="form" label-width="90px" size="small" label-position="left">
      <el-form-item label="行业分类：" prop="tab1">
        <zx-tabs v-model="form.tab1" :list="tabList1"></zx-tabs>
      </el-form-item>
      <el-form-item label="服务分类：" prop="tab2">
        <zx-tabs v-model="form.tab2" :list="tabList2"></zx-tabs>
      </el-form-item>
      <el-form-item label="所在地：" prop="tab3">
        <zx-tabs v-model="form.tab3" :list="tabList3"></zx-tabs>
      </el-form-item>
      <div class="uf uf-ac">
        <el-form-item label-width="0" prop="value2" class="mr-3">
          <zx-sort text="综合" v-model="form.value2"></zx-sort>
        </el-form-item>
        <el-form-item label-width="0" prop="value3" class="mr-3">
          <zx-sort text="最新" v-model="form.value3"></zx-sort>
        </el-form-item>
        <el-form-item label-width="0" prop="value1">
          <zx-search v-model="form.value1" placeholder="请输入服务名称、行业名称、公司名称"></zx-search>
        </el-form-item>
      </div>
    </el-form>
    <div class="items">
      <div class="item uf" v-for="item in tableList" :key="item.id" @click="toService">
        <div class="img">
          <el-image fit="cover" :src="item.imageSrc" class="wi100 hi100"></el-image>
        </div>
        <div class="uf-f1 u-m-l-15 u-flow-hidden">
          <div class="fwb f16 toe">{{ item.title || '-' }}</div>
          <div class="uf uf-ac company u-m-t-10">
            <div class="iconfont icon-company-fill"></div>
            <div class="uf-f1 ml-1 toe">{{ item.company || '-' }}</div>
          </div>
          <div class="u-line-2 lh1 describe u-m-t-6">{{ item.describe || '-' }}</div>
          <el-button type="primary" plain size="small" class="u-m-t-10">立即使用</el-button>
        </div>
      </div>
    </div>
    <div class="u-flex u-row-center u-col-center u-m-t-20">
      <el-pagination
          :current-page="1"
          :page-sizes="[12, 24, 48, 96]"
          :page-size="12"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="24">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ZxTabs from '@/components/ZxTabs/index.vue'
import ZxSearch from '@/components/ZxSearch/index.vue'
import ZxSort from '@/components/ZxSort/index.vue'
export default {
  components: { ZxTabs, ZxSearch, ZxSort },
  data () {
    return {
      form: {
        tab1: '',
        tab2: '',
        tab3: '',
        value1: '',
        value2: '',
        value3: ''
      },
      tabList1: [
        {
          label: '生物医药',
          value: '1'
        },
        {
          label: '机械装备',
          value: '2'
        },
        {
          label: '汽车',
          value: '3'
        },
        {
          label: '厨电',
          value: '4'
        },
        {
          label: '纺织',
          value: '5'
        },
        {
          label: '包装',
          value: '6'
        },
        {
          label: '家具',
          value: '7'
        },
        {
          label: '生鲜食品',
          value: '8'
        },
        {
          label: '电梯',
          value: '9'
        },
        {
          label: '精密模具',
          value: '10'
        },
      ],
      tabList2: [
        {
          label: '知识产权',
          value: '1'
        },
        {
          label: '科技咨询',
          value: '2'
        },
        {
          label: '生产流程',
          value: '3'
        },
        {
          label: '检测检验',
          value: '4'
        },
      ],
      tabList3: [
        {
          label: '山东省',
          value: '1'
        },
        {
          label: '河南省',
          value: '2'
        },
        {
          label: '河北省',
          value: '3'
        },
        {
          label: '安徽省',
          value: '4'
        },
        {
          label: '陕西省',
          value: '5'
        },
        {
          label: '山西省',
          value: '6'
        },
        {
          label: '重庆市',
          value: '7'
        },
        {
          label: '北京市',
          value: '8'
        },
        {
          label: '广东省',
          value: '9'
        },
        {
          label: '上海市',
          value: '10'
        },
        {
          label: '江苏省',
          value: '11'
        },
        {
          label: '浙江省',
          value: '12'
        },
        {
          label: '广东省',
          value: '13'
        },
        {
          label: '天津市',
          value: '14'
        },
      ],
      tableList: [
        {
          id: 1,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg',
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 2,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '药物临床实验与应用',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 3,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 4,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '药物临床实验与应用',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 5,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 6,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '药物临床实验与应用',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 7,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 8,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '药物临床实验与应用',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 9,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 10,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '药物临床实验与应用',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
        {
          id: 11,
          imageSrc: 'https://p.ananas.chaoxing.com/star3/origin/4f1a60d79fc3b1f59fcb3fe59b8cdb84.jpg' + '&t=' + Math.random(),
          title: '基因组学检测分析',
          company: '北京生物科技有限公司',
          describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
        },
      ]
    }
  },
  methods: {
    toService () {
      //
      let id = '80c8e59d54064359a0c7dc4b06903e35'
      this.$router.push({name: 'service', query: {id, isProvider: false}})
    },
  }
}
</script>

<style scoped lang="scss">
  .container{
    width: 1325px;
    margin: 0 auto;
    padding: 30px 15px 15px;
    box-sizing: border-box;
    line-height: 1;
    .items{
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      overflow: hidden;
      .item{
        background-color: #fff;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: 0 0 24px rgba(#000, 0.02);
        overflow: hidden;
        .img{
          width: 33%;
          aspect-ratio: 1;
          border-radius: 8px;
          overflow: hidden;
        }
        .company{
          font-size: 14px;
          color: #333;
        }
        .describe{
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
</style>
